import classNames from 'classnames';
import { Video, Section } from 'types';
import Grid from 'components/Grid';
import { VideoTile } from 'components/VideoTile';
import Icon from 'components/Icon';
import styles from './index.module.scss';

interface VerticalSectionProps {
  section: Section;
  videos: Array<Video>;
}
const VerticalSection: React.FC<VerticalSectionProps> = ({ section, videos }) => {
  const buttonClasses = classNames('hide@md hide@lg', styles.buttonContainer);

  return (
    <div className={styles.verticalSection}>
      <Grid container>
        <Grid item sm={12}>
          <h2 className={styles.verticalHeading}>
            <a href={`section/${section.slug}`}>{section.title}</a>
          </h2>
        </Grid>
      </Grid>

      <Grid container gap="var(--padding-md)" className={styles.verticalContainer}>
        {videos.map((v, i) => (
          <Grid key={v.id} item sm={12} md={6} lg={3} className={i > 1 ? 'hide@sm' : ''}>
            <VideoTile video={v} imageWidth={330} />
          </Grid>
        ))}
      </Grid>

      <Grid container className={buttonClasses}>
        <a href={`/section/${section.slug}`} className={styles.button}>
          Go to {section.title}
          <Icon.ArrowRight color="white" />
        </a>
      </Grid>
    </div>
  );
};

export default VerticalSection;
